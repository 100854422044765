import React, { useState, useEffect, useRef } from "react";
import { init, IInitConfig } from "utils/webphone-service";
// import Modal from "components/common/modal";
import { Modal } from "react-bootstrap";
import CallModal from "components/modal/incoming-call-modal";
import { useStopwatch } from "react-timer-hook";
import { useSelector, useDispatch } from "react-redux";
import UserDropdown from "./user-dropdown";
import { RootState } from "redux/reducers";
import { IWebPhoneConfig, IWebphoneCredentials } from "types/session-types";

import PhoneCallbackIcon from "@material-ui/icons/PhoneCallback";

import style from "./service-panel.module.scss";
import { IReduxActionState, IActionState } from "types/async-types";
import { useReduxAction, useAsyncAction } from "utils/async-action";
import { fetchCustomer, fetchInvoices } from "actions/customer";
import { fetchAgentsStats } from "actions/agents";
import { IAgentStats, IService, IServices } from "types/agent-types";
import { SET_AGENTS_TIMER, ISetAgentsTimer } from "types/redux-types";
import CustomerList from "./service-list";
import GlobalMessage from "./global-message";
import {
  fetchTodayServices,
  fetchOpenServices,
  fetchClosedServices,
  fetchService,
  fetchMyClosedServices,
  fetchIncomingService,
} from "actions/services";
import Spinner from "components/common/spinner";
import axios from "axios";
import { logout } from "utils/auth";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import { WebphoneContext } from "components/dashboard";
declare global {
  interface Window {
    answer: any;
    integralnet: any;
  }
}

// function callOrg() {
//   // let destination = document.getElementById("call-info").dataset;
//   let uuid = undefined;
//   let phone = undefined;
//   let number = phone + "#" + uuid;

//   // $('.dial-screen').text(phone);

//   showOutgoing();
//   webphone.call(number);
// }

const RightPanel = () => {
  const openServiceTokenSource = axios.CancelToken.source();
  const [mockMessagesCount, setMockMessagesCount] = useState(0);
  const [incomingData, setIncomingData] = useState<IService[] | null>(null);
  const [messageVisible, setMessageVisible] = useState(false);
  const servicePanelRef = useRef<HTMLDivElement>(null);
  const servicePanelCurrent = servicePanelRef.current;

  const webphoneContext = React.useContext(WebphoneContext);
  const webphone = webphoneContext?.webphone;
  const webphoneState = webphoneContext?.state;

  useEffect(() => {
    if(webphoneState === 'ready'){
      setIncomingData(null);
    }
    if (webphoneState === "incoming") {
      const message = webphone?.getMessage();
      
      // if (message !== null) {
        execFetchIncomingService(JSON.parse(message?.displayname || ""));
      // }
    }
  }, [webphoneState]);

  const dispatch = useDispatch();

  const [makeCallActive, setMakeCallActive] = useState(false);
  const [incomingCallModalVisible, setIncomingCallModalVisible] = useState(
    false
  );
  const [customerState, execFetchCustomer]: readonly [
    IReduxActionState<any>,
    (phone?: string) => any
  ] = useReduxAction(fetchCustomer, "customer");

  const [fetchServiceState, execFetchService]: readonly [
    IReduxActionState<any>,
    any
  ] = useReduxAction(fetchService, "customer-service");

  const [agentsState, execFetchAgents]: readonly [
    IActionState<IAgentStats>,
    () => any
  ] = useAsyncAction(fetchAgentsStats);

  const [incomingServiceState, execFetchIncomingService]: readonly [
    IActionState<IService>,
    typeof fetchIncomingService
  ] = useAsyncAction(fetchIncomingService);

  const [todayServices, execFetchTodayServices]: readonly [
    IActionState<IServices>,
    any
  ] = useAsyncAction(fetchTodayServices);

  const [openServices, execFetchOpenServices]: readonly [
    IActionState<IServices>,
    any
  ] = useAsyncAction(fetchOpenServices);

  const [closedServices, execFetchClosedServices]: readonly [
    IActionState<IServices>,
    typeof fetchMyClosedServices
  ] = useAsyncAction(fetchMyClosedServices);

  const [logoutState, execLogout]: readonly [
    IActionState<any>,
    typeof logout
  ] = useAsyncAction(logout);

  const user = useSelector((state: RootState) => state.user);
  const async = useSelector((state: RootState) => state.async);
  const currentService = useSelector((state: RootState) => state.service?.currentService);
  const config = useSelector((state: RootState) => state.user.session?.config);

  const fetchAgentsTimer = useSelector(
    (state: RootState) => state.user.fetch_agents_timer
  ); 
  const credentials = useSelector(
    (state: RootState) => state.user.session?.webphone
  );

  async function repeatFetching() {
    await execFetchAgents();
    const timer = setTimeout(repeatFetching, 50000);
    const action: ISetAgentsTimer = {
      type: SET_AGENTS_TIMER,
      payload: timer,
    };
    dispatch(action);
  }

  useEffect(() => {
    //clearing previous timers in case this side effect is fired many times
    let mounted = true;

    if (mounted) {
      clearTimeout(fetchAgentsTimer);
      execFetchAgents();
      repeatFetching();

      execFetchTodayServices();
      execFetchOpenServices(openServiceTokenSource);
      execFetchClosedServices();
    }
  }, []);

  useEffect(() => {
    //clearing previous timers in case this side effect is fired many times
    if (async.succeeded === "create-monit") {
      execFetchTodayServices();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [async.succeeded]);

  useEffect(() => {
    if (incomingServiceState.data) {
      setIncomingData([incomingServiceState.data]);
    }
  }, [incomingServiceState.data]);

  // useEffect(() => {
  //   if (config && credentials && !webphone) {
  //     const initConfig: IInitConfig = {
  //       config: config,
  //       credentials: credentials,
  //       setCallerModalVisible: setIncomingCallModalVisible,
  //     };

  //
  //     const webphone = init(initConfig);
  //     webphone.setAutoAnswer(false);
  //     setWebPhone(webphone);
  //   }
  // }, [config]);

  useEffect(() => {
    if (servicePanelCurrent) {
      servicePanelCurrent.className = `${style["services"]}`;
    }
  }, [currentService]);

  useEffect(() => {
    let mounted = true;
    // if (service?.currentService) {

    execFetchOpenServices(openServiceTokenSource);

    execFetchTodayServices();
    execFetchClosedServices();
    // }

    return function cleanup() {
      mounted = false;
      openServiceTokenSource.cancel();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentService]);

  const answerPhone = () => {
    setMakeCallActive(true);
    // start();

    webphone?.answer();
    setIncomingCallModalVisible(false);

    const caller = webphone?.getCallerNumber();

    const phone = caller
      .replace("sip:", "")
      .replace("@46.242.131.202", "")
      .replaceAll('"', "");

    execFetchCustomer(phone);
  };

  const dismissCall = () => {
    webphone?.hangup();
    setIncomingCallModalVisible(false);
    // reset(undefined, false);
    // pause();
  };

  const disablePanel = () => {
    if (servicePanelCurrent) {
      servicePanelCurrent.className =
        servicePanelCurrent.className + ` ${style["clicked"]}`;
    }
  };

  let caller = null;
  // if (webphone) {
  //   caller = webphone.getCallerNumber();
  //   //
  // }

  if (webphone) {
  }

  const logOut = () => {
    if (user.fetch_agents_timer) {
      clearTimeout(user.fetch_agents_timer);
    }
    execLogout();
  };

  const spinner = () => {
    return (
      <div className="spinner-item">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  };

  const renderMockMessages = () => {
    let result = [];
    for (let i = 0; i < 1; i++) {
      result.push(<GlobalMessage key={"msg" + i} />);
    }
    return <div className="shrink-0">{result}</div>;
  };

  return (
    <>
      <CallModal
        isVisible={incomingCallModalVisible}
        setVisible={setIncomingCallModalVisible}
        caller={caller}
        answerPhone={answerPhone}
        dismissCall={dismissCall}
      />

      {/* <div className="flex-column"> */}

      <div className="panel no-padding flex-column">
        <div className="shrink-0">
          <div className="panel-p no-padding shrink-0">
            <UserDropdown logout={logOut} />
          </div>
          <div className={`${style["status"]} flex  shrink-0`}>
          <div onClick={() => setMessageVisible(!messageVisible)} className="t-center">
              {agentsState.loading ? (
                spinner()
              ) : (
                <>
                  <div className={`${style["count"]}`}>
                    {" "}
                    {agentsState.data?.total}{" "}
                  </div>{" "}
                  <div className="md-con-t"> Agenci łącznie </div>
                </>
              )}
            </div>
            <div className="t-center">
              {agentsState.loading ? (
                spinner()
              ) : (
                <>
                  <div className={`${style["count"]}`}>
                    {agentsState.data?.available}{" "}
                  </div>{" "}
                  <div className="md-con-t"> Dostępni </div>
                </>
              )}
            </div>
            <div className="t-center">
              {agentsState.loading ? (
                spinner()
              ) : (
                <>
                  <div className={`${style["count"]}`}>
                    {agentsState.data?.on_break}
                  </div>{" "}
                  <div className="md-con-t"> Na przerwie </div>
                </>
              )}
            </div>

          </div>
          {messageVisible && renderMockMessages()}
        </div>

        <div ref={servicePanelRef} className={`${style["services"]}`}>
          {(openServices.loading ||
            todayServices.loading ||
            closedServices.loading ||
            async.isLoading ||
            async.property === "issue-action" ||
            async.property === "contacts") && <Spinner position="absolute" />}

          {incomingData && (
            <>
              <div className={`${style["services-header"]}`}>
                <p className="md-con-t mb-0">Połączenia przychodzące</p>
              </div>
              <CustomerList
                execFetchService={execFetchService}
                disablePanel={disablePanel}
                customers={incomingData || []}
                isIncoming={true}
                setIncomingData={setIncomingData}
              />
            </>
          )}
          <div className={`${style["services-header"]}`}>
            <p className="md-con-t mb-0">Obsługiwane:</p>
          </div>

          <CustomerList
            execFetchService={execFetchService}
            disablePanel={disablePanel}
            customers={openServices.data?.list || []}
          />

          {/* <div className={`${style["services-header"]}`}>
              <p className="md-con-t mb-0">Nadchodzące teraz: </p>
            </div>
            <CustomerList customers={incomingServices} /> */}
          <div className={`${style["services-header"]}`}>
            <p className="md-con-t mb-0">Zaplanowane na dziś: </p>
          </div>
          {}
          <CustomerList
            execFetchService={execFetchService}
            disablePanel={disablePanel}
            customers={todayServices.data?.list || []}
          />

          <>
            <div className={`${style["services-header"]}`}>
              <p className="md-con-t">Zakończone: </p>
            </div>
            <div className="mb-15 mt-10">
              <Pagination
                shape="rounded"
                onChange={(e, page) => {
                  execFetchClosedServices(page);
                }}
                page={closedServices.data?.current_page}
                count={closedServices.data?.total_pages}
              />
            </div>
            <CustomerList
              execFetchService={execFetchService}
              disablePanel={disablePanel}
              customers={closedServices.data?.list || []}
            />
          </>
        </div>
      </div>
      {/* </div>~ */}
    </>
  );
};

export default RightPanel;
