const env = process.env.REACT_APP_CUSTOM_ENV || process.env.NODE_ENV





const APIHosts : {[key: string] : string} = {
  development: "https://demo.circle360.pl",
  // development: "http://192.168.17.103:3001",
  test: "https://demo.circle360.pl",
  integrator: "https://integrator.circle360.pl",
  pretorius: "https://pretorius.circle360.pl",
  "pre-production": "https://soz.cts24.pl",
  production : "https://soz.cts24.pl",
  demo1: "https://demo1.circlecc.pl",
  demo2: "https://demo2.circlecc.pl",
  demo3: "https://demo3.circlecc.pl",
};

export const API_BASE_URL = APIHosts[env || 'development'] + "/api";
// export const API_BASE_URL = APIHosts[env || 'development'] ;



